<template>
  <transition name="fade" mode="out-in" appear>
    <dashboard-layout v-if="isAuthenticated">
      <CustomSnackbar />
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in" appear>
          <component :is="Component"></component>
        </transition>
      </router-view>
    </dashboard-layout>
    <ErrorLayout v-else-if="authError" :error="authError" />
    <LoginLayout v-else />
  </transition>
</template>

<script>
import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';
import ErrorLayout from '@/layout/error/ErrorLayout.vue';
import LoginLayout from '@/layout/login/LoginLayout.vue';
import CustomSnackbar from '@/components/snackbars/CustomSnackbar.vue';
import { PublicClientApplication } from '@azure/msal-browser';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',

  components: {
    DashboardLayout,
    LoginLayout,
    ErrorLayout,
    CustomSnackbar,
  },

  data() {
    return {
      authError: null,
    };
  },

  computed: {
    ...mapGetters({
      msalConfig: 'auth/msalConfig',
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },

  created() {
    this.setLocaleViaNavigator();
    this.$msalInstance = new PublicClientApplication(this.msalConfig);

    if (!this.isAuthenticated) {
      console.log('not authenticated');
      this.signIn(this.$msalInstance).catch(err => {
        console.log('error', err);
        this.authError = err;
      });
    }
  },

  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
    }),

    setLocaleViaNavigator() {
      let userLang = navigator.language || navigator.userLanguage;
      if (userLang.includes('fr')) {
        userLang = 'fr';
      } else if (userLang.includes('en')) {
        userLang = 'en';
      } else {
        userLang = 'en';
      }
      this.$i18n.locale = userLang;
    },
  },
};
</script>

<style src="./assets/scss/main.scss" lang="scss"></style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
