<template>
  <!-- <v-container>
    <v-row align="center" justify="center" class="error-layout">
      <v-col class="text-center col-error">
        <h1>error</h1>
        <p>
          {{ error }}
        </p>
      </v-col>
    </v-row>
  </v-container> -->
  <vue-splash
    :show="true"
    title="Oops ! Une erreur est survenue."
    :logo="require('../../assets/img/logo/emn-logo.svg')"
    color="#ea0022"
    backgroundColor="#00094d"
    :size="300"
    :fixed="true"
    :customText="error"
    error
  />
</template>

<script>
import VueSplash from '@/components/loaders/VueSplash';

export default {
  name: 'ErrorLayout',

  props: {
    error: {
      type: Object,
    },
  },
  components: {
    VueSplash,
  },
};
</script>

<style lang="scss" scoped>
.error-layout {
  height: 90vh;
  color: $white;

  .col-error {
    max-width: 500px;
  }
}
</style>
