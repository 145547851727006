// Vuetify
import { createVuetify } from 'vuetify';

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';

// Default icon set
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi';

// Custom icons
import AurionIcon from '@/components/icons/AurionIcon';
import MoodleIcon from '@/components/icons/MoodleIcon';
import UbicastIcon from '@/components/icons/UbicastIcon';

const emnBackOfficeDark = {
  dark: true,
  colors: {
    background: '#06166b',
    surface: '#06166b',
    primary: '#df0015',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

const emnBackOfficeTable = {
  dark: true,
  colors: {
    background: '#000739',
    surface: '#000739',
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'emnBackOfficeDark',
    themes: {
      emnBackOfficeDark,
      emnBackOfficeTable,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      aurion: AurionIcon,
      moodle: MoodleIcon,
      ubicast: UbicastIcon,
    },
    sets: {
      mdi,
    },
  },
  components,
  directives,
});
