import { toRaw } from 'vue';
import {
  getEntities,
  insertEntity,
  updateEntity,
  deleteEntity,
} from '@/services/azureTableService';

const fromEventToAzure = atwEvent => {
  return {
    partitionKey: 'AroundTheWorldEvents',
    rowKey: atwEvent.id.toString(),
    region: atwEvent.region,
    conferenceLink: atwEvent.conferenceLink,
    dateTime: atwEvent.dateTime,
    replayLinks: JSON.stringify(atwEvent.replayLinks),
    isActive: atwEvent.isActive,
  };
};

export default {
  namespaced: true,

  state() {
    return {
      atwEvents: [],
    };
  },

  getters: {
    atwEvents: state => state.atwEvents,
  },

  actions: {
    async getAtwEvents({ commit }) {
      try {
        const atwEvents = await getEntities();
        const transformedAtwEvents = atwEvents.map(atwEvent => {
          return {
            id: atwEvent.rowKey,
            region: atwEvent.region,
            conferenceLink: atwEvent.conferenceLink,
            dateTime: atwEvent.dateTime,
            replayLinks: JSON.parse(atwEvent.replayLinks),
            isActive: atwEvent.isActive,
          };
        });
        commit('setAtwEvents', transformedAtwEvents);
      } catch (error) {
        throw new Error(error);
      }
    },

    async addAtwEvent({ dispatch }, atwEvent) {
      try {
        if (!atwEvent) {
          throw new Error('atwEvent is undefined');
        }
        const rawEvent = toRaw(atwEvent);
        const newAtwEvent = rawEvent;
        const transformedEvent = fromEventToAzure(newAtwEvent);
        await insertEntity(transformedEvent);
        dispatch('getAtwEvents');
      } catch (error) {
        throw new Error(error);
      }
    },

    async updateAtwEvent({ dispatch }, { atwEvent }) {
      const rawEvent = toRaw(atwEvent);
      if (!atwEvent) {
        throw new Error('atwEvent is undefined');
      }

      const entity = {
        partitionKey: 'AroundTheWorldEvents',
        rowKey: rawEvent.id,
        conferenceLink: rawEvent.conferenceLink,
        region: atwEvent.region,
        dateTime: atwEvent.dateTime,
        replayLinks: JSON.stringify(atwEvent.replayLinks),
        isActive: atwEvent.isActive,
      };
      try {
        await updateEntity(entity);
        dispatch('getAtwEvents');
      } catch (error) {
        throw new Error(error);
      }
    },

    async deleteAtwEvent({ dispatch }, atwEvent) {
      if (!atwEvent) {
        throw new Error('atwEvent is undefined');
      }
      const rawEvent = toRaw(atwEvent);
      try {
        await deleteEntity('AroundTheWorldEvents', rawEvent.id);
        dispatch('getAtwEvents');
      } catch (error) {
        throw new Error(error);
      }
    },
  },

  mutations: {
    setAtwEvents(state, atwEvents) {
      state.atwEvents = atwEvents;
    },
  },
};
