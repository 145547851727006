const common = {
  name: 'Name',
  approved: 'Approved',
  actions: 'Actions',
  edit: 'Edit',
  update: 'Update',
  add: 'Add',
  cancel: 'Cancel',
  delete: 'Delete',
  save: 'Save',
  search: 'Search',
  connectionPending: 'Connection pending...',
  welcome: 'Welcome to EMN Tools',
  loading: 'Loading...',
  logout: 'Sign Out',
  startDate: 'Start date',
  endDate: 'End date',
  date: 'Date',
  hours: 'Hours',
  attendees: 'Attendees',
  codification: 'Codification',
  issueDetected: 'Issue detected',
  today: 'Today',
  status: 'Status',
  see: 'See',
  confirm: 'Confirm',
  confirmed: 'Confirmed',
  reject: 'Reject',
  rejected: 'Rejected',
};

export default common;
