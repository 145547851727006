import activityType from './modules/activityType';
import aurion from './modules/aurion';
import common from './modules/common';
import error from './modules/error';
import home from './modules/home';
import menu from './modules/menu';
import reports from './modules/reports';
import rooms from './modules/rooms';
import aroundTheWorld from './modules/aroundTheWorld';

const en = {
  activityType,
  aurion,
  common,
  error,
  home,
  menu,
  reports,
  rooms,
  aroundTheWorld,
};

export default en;
