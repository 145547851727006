const aroundTheWorld = {
  moduleKey: 'aroundTheWorld',
  moduleTitle: 'AroundTheWorld',
  moduleDisplay: true,
  children: [
    {
      title: 'aroundTheWorld',
      icon: 'fluent:person-prohibited-24-regular',
      to: '/around-the-world',
      authorized: ['all'],
    },
  ],
};

export default aroundTheWorld;
