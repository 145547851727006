const common = {
  name: 'Nom',
  approved: 'Approuvé',
  actions: 'Actions',
  edit: 'Éditer',
  update: 'Modifier',
  add: 'Ajouter',
  delete: 'Supprimer',
  cancel: 'Annuler',
  save: 'Sauvegarder',
  search: 'Rechercher',
  connectionPending: 'Connexion en cours...',
  welcome: 'Bienvenue sur EMN Tools',
  loading: 'Chargement...',
  logout: 'Déconnexion',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  date: 'Date',
  hours: 'Heures',
  attendees: 'Participants',
  codification: 'Codification',
  issueDetected: 'Problème detécté',
  today: "Aujourd'hui",
  status: 'Statut',
  see: 'Voir',
  confirm: 'Confirmer',
  confirmed: 'Confirmé',
  reject: 'Rejeter',
  rejected: 'Rejeté',
};

export default common;
