import { http } from '@/plugins/http';

export default {
  namespaced: true,

  state() {
    return {
      countriesEndpoint: 'tools/api/countries',
      countries: [],
    };
  },

  getters: {
    countriesEndpoint: state => state.countriesEndpoint,
    countries: state => state.countries,
  },

  actions: {
    getCountries({ commit, state }, params) {
      return http
        .get(state.countriesEndpoint, { params: params })
        .then(response => {
          commit('setCountries', response.data);
        })
        .catch(error => {
          throw new Error(error);
        });
    },
  },

  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
  },
};
