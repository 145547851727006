import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';

export default {
  namespaced: true,

  state() {
    return {
      msalConfig: {
        auth: {
          clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
          authority: process.env.VUE_APP_MSAL_AUTHORITY,
          redirectUri: process.env.VUE_APP_BASE_URL,
          postLogoutRedirectUri: process.env.VUE_APP_BASE_URL,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      },
      account: null,
      roles: [],
      isAuthenticated: false,
      accessToken: '',
      lastAction: '',
      lastActionPayload: '',
    };
  },

  getters: {
    msalConfig: state => state.msalConfig,
    account: state => {
      return localStorage.userAccount
        ? JSON.parse(localStorage.getItem('userAccount'))
        : state.account;
    },
    roles: state => {
      return localStorage.userRoles
        ? JSON.parse(localStorage.getItem('userRoles'))
        : state.roles;
    },
    isAuthenticated: state => {
      return localStorage.userIsAuthenticated
        ? JSON.parse(localStorage.getItem('userIsAuthenticated'))
        : state.isAuthenticated;
    },
    accessToken: state => {
      return localStorage.userToken
        ? JSON.parse(localStorage.getItem('userToken'))
        : state.accessToken;
    },
    lastAction: state => state.lastAction,
    lastActionPayload: state => state.lastActionPayload,
  },

  actions: {
    redirectLogin({ commit, dispatch }, msalInstance) {
      const loginRequest = {};

      return msalInstance
        .acquireTokenPopup(loginRequest)
        .then(() => {
          const accounts = msalInstance.getAllAccounts();
          msalInstance.setActiveAccount(accounts[0]);
          dispatch('getAccessToken', msalInstance);

          commit('setAccount', accounts[0]);
          commit('setRoles', accounts[0].idTokenClaims.roles);
          commit('setIsAuthenticated', true);
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    signIn({ commit, dispatch }, msalInstance) {
      const loginRequest = {};

      return msalInstance
        .ssoSilent(loginRequest)
        .then(() => {
          console.log('Silent login successful');
          const accounts = msalInstance.getAllAccounts();
          msalInstance.setActiveAccount(accounts[0]);
          dispatch('getAccessToken', msalInstance);

          commit('setAccount', accounts[0]);
          commit('setRoles', accounts[0].idTokenClaims.roles);
          commit('setIsAuthenticated', true);
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            dispatch('redirectLogin', msalInstance);
          } else {
            throw new Error(error);
          }
        });
    },

    signOut({ commit, state }) {
      const msalInstance = new PublicClientApplication(state.msalConfig);

      const logoutRequest = {
        account: msalInstance.getAccountByHomeId(state.account.homeAccountId),
      };

      return msalInstance
        .logoutRedirect(logoutRequest)
        .then(() => {
          commit('setAccount', null);
          commit('setRoles', []);
          commit('setIsAuthenticated', false);
          localStorage.clear();
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    getAccessToken({ commit, dispatch }, msalInstance) {
      const tokenRequest = {
        scopes: process.env.VUE_APP_MSAL_SCOPES.split(';'),
      };
      console.log('Getting access token');
      return msalInstance
        .acquireTokenSilent(tokenRequest)
        .then(response => {
          commit('setAccessToken', response.accessToken);
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            dispatch('redirectLogin', msalInstance);
          } else {
            throw new Error(error);
          }
        });
    },
  },

  mutations: {
    setAccount(state, account) {
      localStorage.setItem('userAccount', JSON.stringify(account));
      state.account = account;
    },

    setRoles(state, roles) {
      localStorage.setItem('userRoles', JSON.stringify(roles));
      state.roles = roles;
    },

    setIsAuthenticated(state, isAuthenticated) {
      localStorage.setItem(
        'userIsAuthenticated',
        JSON.stringify(isAuthenticated),
      );
      state.isAuthenticated = isAuthenticated;
    },

    setAccessToken(state, token) {
      localStorage.setItem('userToken', JSON.stringify(token));
      state.accessToken = token;
    },
  },
};
