import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(to => {
  const roles = JSON.parse(localStorage.getItem('userRoles'));
  console.log(roles)
  const routeAuthorizations = to.meta.authorized;

  const canAccess =
    routeAuthorizations.includes('all') ||
    routeAuthorizations.some(r => roles.includes(r)) ||
    roles.includes('BackOffice.Administrator');
  if (!canAccess) return '/error';
});

export default router;
