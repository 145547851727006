import error from './modules/error';
import home from './modules/home';
import referencials from './modules/referencials';
// import reports from './modules/reports';
import rooms from './modules/rooms';
// import synchronisations from './modules/synchronisations';
import aroundTheWorld from './modules/around_the_world';

const routes = [
  error,
  home,
  referencials,
  // reports,
  rooms,
  // synchronisations,
  aroundTheWorld,
];

export default routes;
