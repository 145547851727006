const aroundTheWorld = {
  northAmerica: 'Amérique du Nord',
  southAmerica: 'Amérique du Sud',
  westernEurope: "Europe de l'Ouest",
  easternEurope: "Europe de l'Est",
  northernEurope: 'Europe du Nord',
  southernEurope: 'Europe du Sud',
  africa: 'Afrique',
  asia: 'Asie',
  oceania: 'Océanie',
};

export default aroundTheWorld;
