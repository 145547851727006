import { TableClient, AzureSASCredential } from '@azure/data-tables';

const accountName = process.env.VUE_APP_AZURE_ACCOUNT_NAME;
const sasToken = process.env.VUE_APP_AZURE_SAS_TOKEN;
const tableName = process.env.VUE_APP_AZURE_TABLE_NAME;

const tableClient = new TableClient(
  `https://${accountName}.table.core.windows.net`,
  tableName,
  new AzureSASCredential(sasToken),
);

export const getEntities = async queryOptions => {
  const entities = [];
  const entitiesIter = tableClient.listEntities(queryOptions);
  for await (const entity of entitiesIter) {
    entities.push(entity);
  }
  return entities;
};

export const insertEntity = async entity => {
  await tableClient.createEntity(entity);
};

export const updateEntity = async entity => {
  await tableClient.updateEntity(entity, 'Replace');
};

export const deleteEntity = async (partitionKey, rowKey) => {
  await tableClient.deleteEntity(partitionKey, rowKey);
};
