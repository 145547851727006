const aroundTheWorld = {
  path: '/around-the-world',
  name: 'AroundTheWorld',
  component: () => {
    return import('@/views/AroundtheWorld/AroundTheWorld.vue');
  },
  meta: {
    requiredAuth: true,
    authorized: ['all'],
  },
};

export default aroundTheWorld;
