const aroundTheWorld = {
  northAmerica: 'North America',
  southAmerica: 'South America',
  westernEurope: 'Western Europe',
  easternEurope: 'Eastern Europe',
  northernEurope: 'Northern Europe',
  southernEurope: 'Southern Europe',
  africa: 'Africa',
  asia: 'Asia',
  oceania: 'Oceania',
};

export default aroundTheWorld;
